import { MicrosoftToken } from "../objects/tokens/MicrosoftToken";
import { getMicrosoftProfile } from "./AuthServices";

/**
 * Access control functions to determine if a component should render.  This should not be substituted as a form of security.
 * All API routes should be secured on the server.
 * 
 * ROLES (in order of access)
 * 
 * DEV
 * ADMIN
 * ACCTNG
 * OPS
 * RMNGR
 * PCOORD
 * MNGR
 * SALES
 * 
 */


export function checkIfDev() {
	let accessGranted = false;
	let microsoftProfile: MicrosoftToken = getMicrosoftProfile();
	microsoftProfile.roles.forEach(role => {
		if (role === "DEV") {
			accessGranted = true;
		} 
	});
	return accessGranted;
	
}

export function checkIfAdmin() {
	let accessGranted = false;
	let microsoftProfile: MicrosoftToken = getMicrosoftProfile();
	microsoftProfile.roles.forEach(role => {
		if (checkIfDev() || role === "ADMIN") {
			accessGranted = true;
		} 
	});
	return accessGranted;
}

export function checkIfAccounting() {
	let accessGranted = false;
	let microsoftProfile: MicrosoftToken = getMicrosoftProfile();
	microsoftProfile.roles.forEach(role => {
		if (checkIfDev() || checkIfAdmin() || checkIfManager() || role === "ACCTNG") {
			accessGranted = true;
		} 
	});
	return accessGranted;
}

export function checkIfOps() {
	let accessGranted = false;
	let microsoftProfile: MicrosoftToken = getMicrosoftProfile();
	microsoftProfile.roles.forEach(role => {
		if (checkIfDev() || checkIfAdmin() || role === "OPS") {
			accessGranted = true;
		} 
	});
	return accessGranted;
}

export function checkIfRegionalManager() {
	let accessGranted = false;
	let microsoftProfile: MicrosoftToken = getMicrosoftProfile();
	microsoftProfile.roles.forEach(role => {
		if (checkIfDev() || checkIfAdmin() || checkIfManager() || role === "RMNGR") {
			accessGranted = true;
		} 
	});
	return accessGranted;
	
}

export function checkIfProjectCoord() {
	let accessGranted = false;
	let microsoftProfile: MicrosoftToken = getMicrosoftProfile();
	microsoftProfile.roles.forEach(role => {
		if (checkIfDev() || checkIfAdmin() || checkIfManager() || role === "PCOORD") {
			accessGranted = true;
		} 
	});
	return accessGranted;
}

export function checkIfManager() {
	let accessGranted = false;
	let microsoftProfile: MicrosoftToken = getMicrosoftProfile();
	microsoftProfile.roles.forEach(role => {
		if (checkIfDev() || checkIfAdmin() || role === "MNGR" || role === "PCOORD") {
			accessGranted = true;
		} 
	});
	return accessGranted;
}

export function checkIfSales() {
	
}