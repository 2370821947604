export class ApplicationContext {
    path: string;
    pathObjectId: string;
    history: string[];

    msalIdToken: string;
    accessToken: string;
    loggedIn: boolean;
    account: any;

    appSettings: string;

    static instance: ApplicationContext;

    private constructor() {
        this.path = "";
        this.pathObjectId = "";
        this.accessToken = "";
        this.msalIdToken = "";
        this.loggedIn = false;
        this.account = null;
        this.appSettings = "";
    }

    public static getInstance(): ApplicationContext {
        if (!ApplicationContext.instance) {
            ApplicationContext.instance = new ApplicationContext();
        }
        return ApplicationContext.instance;
    }

}