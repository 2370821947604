import React, { useState } from 'react';
import UserProvider from '../../objects/user/UserContext';
import Navbar from '../navbar/Navbar';
import UserUI from '../../components/userui/UserUI';

const Dashboard = () => {
	const [tab, setTab] = useState(0);

	function changeTab(value: any) {
		setTab(value);
	}

  return(
      <UserProvider >
          <Navbar tab={tab} changeTab={changeTab}/>
          <UserUI/>
      </UserProvider>
  )
};

export default Dashboard;