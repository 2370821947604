import * as React from 'react';
import { getMicrosoftProfile } from '../../auth/AuthServices';
import { CheckHttpResponse } from '../../services/CheckHttpResponse';
import { createUserProfile, deleteUserProfile, getUserByEmail, markUserProfileDeleted, updateMyProfile, updateUserProfile } from './UserServices';
import { MicrosoftToken } from '../tokens/MicrosoftToken';
import { IUser, UserContextType } from './@types/user';

export const UserContext = React.createContext<UserContextType | null>(null);

const UserProvider: React.FC<React.ReactNode> = ({ children }) => {

  
  const [user, setUser] = React.useState<IUser>(  
    {
      id: "",
      firstName: "",
      lastName: "",
      email: "",
      phoneNumber: "",
      jobTitle: "",
      npn: "",
      locationId: "",
      roleId: "",
      appSettings: "",
      assignable: false,
      whoOwnerCode: "",
      createdBy: "",
      creationTime: "",
      modifiedBy: "",
      modificationTime: "",
      deleted: false
    }
  );

  const [loadingUser, setLoadingUserData] = React.useState(true);
  const [loadingInterval, setLoadingInterval] = React.useState(true);

  const setLoadingUser = (_loadingUser: boolean) => {
    setLoadingUserData(_loadingUser);
    setLoadingInterval(_loadingUser);
  };

  const loadUser = () => {
    setLoadingInterval(false);
    const microsoftProfile: MicrosoftToken = getMicrosoftProfile();
    getUserByEmail(microsoftProfile.preferred_username).then(res => {
      if(CheckHttpResponse(res)) {
        setLoadingUser(false);
        setUser(res.data);
      } else if(res.status === 404 || res.data === "") {
        console.log( "404" );
        user.email = microsoftProfile.preferred_username;
        user.firstName = microsoftProfile.name.split(" ")[0];
        user.lastName = microsoftProfile.name.split(" ")[1];
        user.whoOwnerCode = user.lastName.substring(0,3).toUpperCase() + user.firstName.substring(0,2).toUpperCase() + "1";

        createUserProfile(user).then(res => {
          setLoadingUser(false);
          if(CheckHttpResponse(res)) {
            setUser(res.data);
          } else if(res.status === 409) {
            window.location.reload();
          } else {
            window.location.reload();
          }
        });
      } else {
        console.log( res.status );
      }
    });
  };

    const updateMyUserProfile = (user: IUser) => {
        console.log(user);
        updateMyProfile(user).then(res => {
        });
    };

    const updateUser = (name: string, value: string) => {
      //console.log("name: " + name + " value: " + value);
      setUser({
        ...user,
        [name]: value
      });
    };
  
    const saveUser = (user: IUser) => {
      console.log(user);
      updateUserProfile(user).then(res => {
      });
    };
  
    const setSettings = (user: IUser, settingsName: string, settingsValue: string) => {
      let object: Object = {};
      if(user.appSettings !== "") {
        object = JSON.parse(user.appSettings);
      }
      console.log(object);
  
      // @ts-ignore
      object[settingsName] = settingsValue;
      console.log(object);
  
      setUser({
        ...user,
        appSettings: JSON.stringify(object)
      });
        
      saveUser({
        ...user,
        appSettings: JSON.stringify(object)
      });
    };
  
    const getSettings = (user: IUser, settingsName: string) => {
      console.log(user);
      console.log(settingsName);
      let returnVal: string = "";
  
      if(user.appSettings !== "") {
        let object: Object = JSON.parse(user.appSettings);
        // @ts-ignore
        returnVal = object[settingsName];
      }
      return returnVal;
    };
  
    const deleteUser = (user: IUser) => {
      deleteUserProfile(user).then(res => {
        if(CheckHttpResponse(res)) {
          window.location.reload();
        }
      });
    };
  
    const markUserDeleted = (user: IUser) => {
      markUserProfileDeleted(user).then(res => {
        if(CheckHttpResponse(res)) {
          window.location.reload();
        }
      });
    };
  
    if(loadingInterval === true) {
      loadUser();
    };
  
    return <UserContext.Provider value={{
      user, loadingUser, setLoadingUserData, saveUser,
      setSettings, getSettings,
      updateUser, updateMyUserProfile,
      deleteUser, markUserDeleted
    }}>{children}</UserContext.Provider>
  };
  
  export default UserProvider;