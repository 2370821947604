import axios from 'axios';
import { checkIdTokenExpiration } from '../auth/AuthServices';
import { ApplicationContext } from '../objects/ApplicationContext';

//var axiosInterceptor = axios.create({withCredentials: true});
var axiosInterceptor = axios.create();


// request interceptor
axiosInterceptor.interceptors.request.use(config => {

	var applicationContext = ApplicationContext.getInstance();
	// console.log("INTERCEPTOR");
	//var accessToken = store.getState().application?.msalIdToken;
	var accessToken = applicationContext.msalIdToken;
	if(accessToken !== "") {
		if(checkIdTokenExpiration(accessToken || "")) {
			// console.log("Access token expired");
			applicationContext.loggedIn = false;
			config.headers = {};
			config.url = "";
			throw new axios.Cancel('Expired token: action aborted');
		} else {
			// console.log("***************ACCESS TOKEN VALID***************");
			// var accessToken = getMsalIDToken();
	
			// console.log(accessToken);
			if(accessToken) {
				if(config.method !== 'OPTIONS') {
					config.headers = {
						'Accept': 'application/json',
						'Content-Type': 'application/json',
						'Authorization': 'Bearer ' + accessToken,
					}
				}
			}
		}
	}
	
	
	return config;
}, error => {
	return Promise.reject(error);
});

export default axiosInterceptor;

