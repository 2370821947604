import {IUser} from './@types/user'

export class UserDTO {
	firstName: string;
	lastName: string;
	email: string;
	locationId: string;
	appSettings: string;
  assignable: boolean;
  whoOwnerCode: string;
  deleted: boolean;

	constructor(user: IUser) {
    this.firstName = user.firstName;
    this.lastName = user.lastName;
    this.email = user.email;
    this.locationId = user.locationId;
    this.appSettings = user.appSettings;
    this.assignable = user.assignable;
    this.whoOwnerCode = user.whoOwnerCode;
    this.deleted = user.deleted;
  }
}