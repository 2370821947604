import axiosInterceptor from "../../services/AxiosInterceptor";
import { PATH } from "../../ApplicationConfiguration";
import { IUser } from "./@types/user";
import { UserDTO } from "./UserDTO";

const userApiName: string = "/users";


export function createUserProfile(user: IUser) {

  let userDto: UserDTO = new UserDTO(user);

	return axiosInterceptor.post(PATH + userApiName + '/users', userDto).then(response => {
    console.log(response);
    return Promise.resolve(response);
  }).catch((error) => {
    console.log(error);
    if(error.response !== undefined && error.response !== null) {
      return Promise.resolve(error.response);
    } else {
      return Promise.resolve(error);
    }
	});
};

export function updateUserProfile(user: IUser) {
	return axiosInterceptor.put(PATH + userApiName + '/users/' + user.id, user).then(response => {
    console.log(response);
    return Promise.resolve(response);
  }).catch((error) => {
    console.log(error);
    if(error.response !== undefined && error.response !== null) {
      return Promise.resolve(error.response);
    } else {
      return Promise.resolve(error);
    }
	});
};

export function updateMyProfile(user: IUser) {
  return axiosInterceptor.put(PATH + userApiName + '/updatemyprofile', user).then(response => {
      console.log(response);
      return Promise.resolve(response);
  }).catch((error) => {
      console.log(error);
      if(error.response !== undefined && error.response !== null) {
      return Promise.resolve(error.response);
    } else {
      return Promise.resolve(error);
    }
  });
};

export function deleteUserProfile(user: IUser) {
	return axiosInterceptor.delete(PATH + userApiName + '/users/' + user.id).then(response => {
    console.log(response);
    return Promise.resolve(response);
  }).catch((error) => {
    console.log(error);
    if(error.response !== undefined && error.response !== null) {
      return Promise.resolve(error.response);
    } else {
      return Promise.resolve(error);
    }
	});
};

export function markUserProfileDeleted(user: IUser) {

  // Mark the user profile as deleted
  let userDto: UserDTO = new UserDTO(user);
  userDto.deleted = true;

  return axiosInterceptor.put(PATH + userApiName + '/users/' + user.id, userDto).then(response => {
    console.log(response);
    return Promise.resolve(response);
  }).catch((error) => {
    console.log(error);
    if(error.response !== undefined && error.response !== null) {
      return Promise.resolve(error.response);
    } else {
      return Promise.resolve(error);
    }
	});
};

export function getAllUsers() {
	return axiosInterceptor.get(PATH + userApiName + '/list/all' ).then(response => {
    console.log(response);
    return Promise.resolve(response);
  }).catch((error) => {
      console.log(error);
      if(error.response !== undefined && error.response !== null) {
      return Promise.resolve(error.response);
    } else {
      return Promise.resolve(error);
    }
	});
};

export function getAllNotDeletedUsers() {
	return axiosInterceptor.get(PATH + userApiName + '/list' ).then(response => {
    console.log(response);
    return Promise.resolve(response);
  }).catch((error) => {
      console.log(error);
      if(error.response !== undefined && error.response !== null) {
      return Promise.resolve(error.response);
    } else {
      return Promise.resolve(error);
    }
	});
};

export function getUsersPageable(page: number, size: number, sort: string) {
	return axiosInterceptor.get(PATH + userApiName + '/list/pageable', {
		params: {
			page: page,
			sort: sort,
			size: size,
    }
  }).then(response => {
    console.log(response);
    return Promise.resolve(response);
  }).catch((error) => {
    console.log(error);
    if(error.response !== undefined && error.response !== null) {
      return Promise.resolve(error.response);
    } else {
      return Promise.resolve(error);
    }
	});
};

export function getUserByEmail(email: string) {
	return axiosInterceptor.get(PATH + userApiName + '/email/' + email).then(response => {
    console.log(response);
    return Promise.resolve(response);
  }).catch((error) => {
    console.log(error);
    if(error.response !== undefined && error.response !== null) {
      return Promise.resolve(error.response);
    } else {
      return Promise.resolve(error);
    }
	});
};

export function getUserById(userId: string) {
  return axiosInterceptor.get(PATH + userApiName + '/users/' + userId).then(response => {
    console.log(response);
    return Promise.resolve(response);
  }).catch((error) => {
    console.log(error);
    if(error.response !== undefined && error.response !== null) {
      return Promise.resolve(error.response);
    } else {
      return Promise.resolve(error);
    }
	});
};